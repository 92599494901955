@import './../_variables';

.wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    backdrop-filter: blur(5px);
    background: rgba($color: #000000, $alpha: .25);

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;

    &.open {
        opacity: 1;
        visibility: visible;

        .modal {transform: translateY(0)}
    }

    &.close {
        opacity: 0;
        visibility: hidden;
    }

    .modal {
        // width: auto;
        // height: auto;
        padding: 10px;
        border-radius: 40px;

        transition: all .4s ease;
        transform: translateY(-600px);
    }
}

@include Scroll(wrapper);