@import './../_variables';

.fieldset {
    width: auto;
    padding: 2px 4px 4px 4px;    
    border: 1px solid $color_accent;
    border-radius: $border_radius_primary;

    &_content {
        overflow: hidden;
        width: 100%;
        gap: 6px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.legend {
    padding: 0 6px;
}
