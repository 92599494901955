.drawer-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    backdrop-filter: blur(5px);
    transition: opacity .4s ease-out;
    background: rgba($color: #000000, $alpha: .25);

    &-content {
        padding: 2px;
        position: fixed;
        display: grid;
        overflow: hidden;
        transition: transform .4s ease-out;
    }

    .drawer-top {
        top: 0;
        left: 0;
        right: 0;
        padding-bottom: 10px;
    }    

    .drawer-bottom {
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 10px;
    }    

    .drawer-left {
        top: 0;
        left: 0;
        bottom: 0;
        padding-right: 10px;
    }    

    .drawer-right {
        top: 0;
        right: 0;
        bottom: 0;
        padding-left: 10px;
    }    
}