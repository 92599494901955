$bg_primary: #FFF;
$bg_secondary: #303B44;
$bg_accent: #f34e2e;

$bg_gradient_primary: linear-gradient(0deg, #f0f0f0 0%, #ffffff 100%);
$bg_gradient_secondary: linear-gradient(0deg, #303B44 0%, #d5d7d9 100%);
$bg_gradient_accent: linear-gradient(0deg, #f34e2e 0%, #f9a696 100%);

$shadow_primary: #303B44;
$shadow_secondary: #FFF;
$shadow_accent: #f34e2e;

$color_primary: #303B44;
$color_secondary: #fff;
$color_accent: #f34e2e;

$border_radius_primary: 10px;

@mixin Scroll ($name) {
    .#{$name} {
        &::-webkit-scrollbar {width: 6px}
        &::-webkit-scrollbar-track {background: #d5d7d9}
        &::-webkit-scrollbar-thumb {background: #2C2C2C}
        &::-webkit-scrollbar-thumb:hover {background: #f34e2e}
        &::-webkit-scrollbar-thumb:active {background: #13171b}
        &::-webkit-scrollbar-track,  &::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb:hover, &::-webkit-scrollbar-thumb:active {border-radius: $border_radius_primary}
    }
}