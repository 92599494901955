@import './../_variables';


.card {
    overflow: hidden;
    background: $bg_secondary;
    border-radius: $border_radius_primary;
    box-shadow: 0 0 2px 1px rgba($color: #000, $alpha: .20) inset;

    .header {        
        letter-spacing: 1.5px;
        color: $color_secondary;
    }

    .content {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 1.5px;

        padding: 6px;
        flex-grow: 1;
        overflow: hidden;
        position: relative;
        color: $color_primary;
        background: $bg_primary;
        border-radius: $border_radius_primary - 2px;
        box-shadow: 0px 0px 4px .2px rgba($color: $shadow_primary, $alpha: 1) inset;
    }
}