@import './_variables.scss';

.rc_root,
.rc_shortcut_cal_root {
    --rc-color-transparent: transparent;
    --rc-color-text-dark: #353535;
    --rc-color-text-inactive: #c9c9ca;
    --rc-color-text-light: #353535;
    --rc-color-bg-light: #fff;
    --rc-color-border: #f0f0f0;
    --rc-color-text-hover: #fff;
    --rc-hsl-primary-hue: 208deg;
    --rc-hsl-primary-saturation: 77%;
    --rc-hsl-primary-light: 47%;
    --rc-hsl-accent-hue: 0deg;
    --rc-hsl-accent-saturation: 77%;
    --rc-hsl-accent-light: 47%;
    --rc-color-primary: hsl(var(--rc-hsl-primary-hue) var(--rc-hsl-primary-saturation) var(--rc-hsl-primary-light));
    --rc-color-primary-light: hsla(var(--rc-hsl-primary-hue) var(--rc-hsl-primary-saturation) var(--rc-hsl-primary-light)/40%);
    --rc-color-primary-lighter: hsla(var(--rc-hsl-primary-hue) var(--rc-hsl-primary-saturation) var(--rc-hsl-primary-light)/8%);
    --rc-color-accent: hsl(var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light));
    --rc-color-accent-light: hsla(var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light)/40%);
    --rc-color-accent-lighter: hsla(var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light)/8%);
    --rc-color-border-root: var(--rc-color-border);
    --rc-color-bg-text-hover-header-button: var(--rc-color-text-hover);
    --rc-color-text-today: var(--rc-color-primary);
    --rc-color-border-weekdays: var(--rc-color-border);
    --rc-color-text-weekday-label: var(--rc-color-text-inactive);
    --rc-color-text-weekend-label: var(--rc-color-accent-light);
    --rc-color-text-dom-inactive: var(--rc-color-text-inactive);
    --rc-color-text-dom-active: var(--rc-color-text-dark);
    --rc-color-text-dom-weekend-active: var(--rc-color-accent);
    --rc-color-text-dom-weekend-inactive: var(--rc-color-accent-light);
    --rc-color-bg-dom-in-range-active: var(--rc-color-primary-lighter);
    --rc-color-bg-dom-in-range-inactive: var(--rc-color-primary-lighter);
    --rc-color-bg-dom-in-range-weekend-active: var(--rc-color-accent-lighter);
    --rc-color-bg-dom-range-ep-active: var(--rc-color-primary);
    --rc-color-bg-dom-range-ep-inactive: var(--rc-color-primary-lighter);
    --rc-color-bg-dom-range-ep-weekend-active: var(--rc-color-accent);
    --rc-color-bg-dom-range-ep-weekend-inactive: var(--rc-color-accent-lighter);
    --rc-color-text-dom-range-ep-weekend-inactive: var(--rc-color-accent-light);
    --rc-color-text-dom-range-ep-active: var(--rc-color-text-light);
    --rc-color-text-dom-range-ep-inactive: var(--rc-color-primary-light);
    --rc-color-bg-dom-selected: var(--rc-color-primary);
    --rc-color-bg-dom-weekend-selected: var(--rc-color-accent);
    --rc-color-text-dom-selected: var(--rc-color-text-light);
    --rc-color-text-dom-weekend-selected: var(--rc-color-text-light);
    --rc-color-text-disabled: var(--rc-color-text-inactive);
    --rc-color-text-disabled-weekend: var(--rc-color-accent-light);
    --rc-color-bg-disabled: var(--rc-color-bg-light);
    --rc-color-bg-disabled-cross: var(--rc-color-text-inactive);
    --rc-color-bg-disabled-weekend-cross: var(--rc-color-accent-light)
}

.rc_root.rc_dark,
.rc_shortcut_cal_root.rc_dark {
    --rc-color-transparent: transparent;
    --rc-color-text-dark: #fff;
    --rc-color-text-inactive: #727273;
    --rc-color-text-light: #353535;
    --rc-color-bg-light: #353535;
    --rc-color-border: #4e4d4d;
    --rc-color-text-hover: #403e3e;
    --rc-color-primary: #cdd8ea;
    --rc-color-primary-light: #6c6f7f;
    --rc-color-primary-lighter: rgba(205, 216, 234, .18);
    --rc-color-accent: #eacdcd;
    --rc-color-accent-light: #7f6c6c;
    --rc-color-accent-lighter: hsla(0, 40%, 86%, .18);
    --rc-color-shorcut-button: #ced9ea;
    --rc-color-shorcut-button-text: #353535;
    --rc-color-shorcut-button-hover: #b5c3db
}

.rc_root {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    font-size: 14px;
}

.rc,
.rc_root {
    position: relative
}

.rc_root.rc_dual .rc+.rc {
    margin-left: 12px
}

.rc_root.rc_dual .rc+.rc:before {
    background-color: var(--rc-color-border);
    content: "";
    height: 1px;
    left: -12px;
    position: absolute;
    top: 25%;
    width: 12px
}

.rc_root * {
    -webkit-tap-highlight-color: var(--rc-color-transparent);
    box-sizing: border-box;
    font-family: inherit
}

.rc_root button {
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    background: transparent;
    border: none;
    color: inherit;
    font: inherit;
    line-height: normal;
    margin: 0;
    overflow: visible;
    padding: 0;
    width: auto
}

.rc_root button::-moz-focus-inner {
    border: 0;
    padding: 0
}

.rc_header_label,
.rc_header_nav {
    border-radius: 6px;
    cursor: pointer
}

.rc_header_label div+div {
    margin-left: 2.5%
}

.rc_header span {
    color: var(--rc-color-text-dark);
    font-size: .85em
}

.rc_header button:hover {
    background-color: var(--rc-color-bg-text-hover-header-button)
}

.rc_header button:active {
    transform: translateY(1px)
}

.rc_body {
    width: 100%
}

.rc_shortcut_cal_root {
    background-color: var(--rc-color-bg-light);
    border: 1px solid var(--rc-color-border-root);
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden
}

.rc_shortcut_cal_root .rc_root {
    border: none;
    border-radius: 0;
    margin: 0
}

.rc_shortcuts_view {
    background-color: var(--rc-color-bg-light)
}

.rc_shortcut_cal_root.rc_dir-left .rc_shortcuts_view {
    border-right: 1px solid var(--rc-color-border)
}

.rc_shortcut_cal_root.rc_dir-right .rc_shortcuts_view {
    border-left: 1px solid var(--rc-color-border)
}

.rc_shortcut_cal_root.rc_dir-bottom .rc_shortcuts_view {
    border-top: 1px solid var(--rc-color-border)
}

.rc_body-months .rc_body-cell button {
    color: var(--rc-color-text-dom-active);
    cursor: pointer;
    font-size: .85em
}

.rc_body-months .rc_body-cell button:hover {
    background-color: var(--rc-color-bg-text-hover-header-button)
}

.rc_body-months .rc_body-cell.rc_this_month {
    border-radius: 6px;
    color: var(--rc-color-text-today)
}

.rc_body-years .rc_body-cell button {
    color: var(--rc-color-text-dom-active);
    cursor: pointer;
    font-size: .85em
}

.rc_body-years .rc_body-cell button:hover {
    background-color: var(--rc-color-bg-text-hover-header-button)
}

.rc_body-years .rc_body-cell.rc_this_year {
    border-radius: 6px;
    color: var(--rc-color-text-today)
}

.rc_body-weekdays {
    border-bottom: 1px solid var(--rc-color-border-weekdays)
}

.rc_body-weekdays_cell {
    color: var(--rc-color-text-weekday-label);
    font-size: .83em;
    text-transform: uppercase
}

.rc_body-weekdays_cell.rc_wknd {
    color: var(--rc-color-text-weekend-label)
}

.rc_body-days-of-month .rc_body-cell {
    cursor: pointer
}

.rc_body-days-of-month .rc_body-cell .rc_body-cell_value {
    border-radius: $border_radius_primary;
    cursor: pointer
}

.rc_no_range_padding .rc_body-days-of-month .rc_body-cell .rc_body-cell_value {
    border-radius: 0
}

.rc_body-days-of-month .rc_body-cell .rc_body-cell_value:hover {
    background-color: var(--rc-color-bg-text-hover-header-button)
}

.rc_body-days-of-month .rc_body-cell button {
    color: var(--rc-color-text-dom-inactive);
    cursor: pointer;
    font-size: .83em
}

.rc_body-days-of-month .rc_body-cell.rc_active button {
    color: var(--rc-color-text-dom-active)
}

.rc_body-days-of-month .rc_body-cell.rc_wknd button {
    color: $color_accent
}

.rc_body-days-of-month .rc_body-cell.rc_wknd:not(.rc_active) button {
    color: var(--rc-color-text-dom-weekend-inactive)
}

.rc_body-days-of-month .rc_body-cell.rc_in_range .rc_body-cell_value {
    background-color: var(--rc-color-bg-dom-in-range-active);
    color: var(--rc-color-text-dark)
}

.rc_no_range_padding .rc_body-days-of-month .rc_body-cell.rc_range_end .rc_body-cell_value {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px
}

.rc_no_range_padding .rc_body-days-of-month .rc_body-cell.rc_range_start .rc_body-cell_value {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px
}

.rc_body-days-of-month .rc_body-cell.rc_in_range.rc_wknd .rc_body-cell_value {
    background-color: var(--rc-color-bg-dom-in-range-weekend-active);
    color: var(--rc-color-text-dark)
}

.rc_body-days-of-month .rc_body-cell.rc_range_end button,
.rc_body-days-of-month .rc_body-cell.rc_range_start button,
.rc_body-days-of-month .rc_body-cell.rc_wknd.rc_range_end button,
.rc_body-days-of-month .rc_body-cell.rc_wknd.rc_range_start button {
    color: var(--rc-color-text-dom-range-ep-active)
}

.rc_body-days-of-month .rc_body-cell.rc_range_end .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_range_start .rc_body-cell_value {
    background-color: var(--rc-color-bg-dom-range-ep-active)
}

.rc_body-days-of-month .rc_body-cell.rc_range_end.rc_wknd .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_range_start.rc_wknd .rc_body-cell_value {
    background-color: var(--rc-color-bg-dom-range-ep-weekend-active)
}

.rc_body-days-of-month .rc_body-cell.rc_range_end.rc_wknd.rc_active button,
.rc_body-days-of-month .rc_body-cell.rc_range_start.rc_wknd.rc_active button {
    border-bottom-color: var(--rc-color-bg-dom-range-ep-weekend-active)
}

.rc_body-days-of-month .rc_body-cell.rc_highlight:not(.rc_disabled) button,
.rc_body-days-of-month .rc_body-cell.rc_today:not(.rc_disabled) button {
    border-bottom: 3px solid $color_accent
}

.rc_body-days-of-month .rc_body-cell.rc_selected .rc_body-cell_value {
    background-color: rgba(48, 59, 68, 0.15)
}

.rc_body-days-of-month .rc_body-cell.rc_selected button {
    color: var(--rc-color-text-dom-selected)
}

.rc_body-days-of-month .rc_body-cell.rc_selected.rc_wknd .rc_body-cell_value {
    background-color: var(--rc-color-bg-dom-weekend-selected)
}

.rc_body-days-of-month .rc_body-cell.rc_selected.rc_wknd button {
    color: var(--rc-color-text-dom-weekend-selected)
}

.rc_body-days-of-month .rc_body-cell.rc_disabled {
    position: relative
}

.rc_body-days-of-month .rc_body-cell.rc_disabled .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_in_range .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_in_range.rc_wknd .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_wknd .rc_body-cell_value {
    background-color: var(--rc-color-bg-disabled)
}

.rc_body-days-of-month .rc_body-cell.rc_disabled .rc_body-cell_value button {
    color: var(--rc-color-text-disabled);
    cursor: none;
    pointer-events: none
}

.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_wknd .rc_body-cell_value button {
    color: var(--rc-color-text-disabled-weekend)
}

.rc_body-days-of-month .rc_body-cell.rc_disabled .rc_body-cell_value button:before {
    background-color: var(--rc-color-bg-disabled-cross);
    content: "";
    display: block;
    font-weight: 300;
    height: 70%;
    left: 50%;
    position: absolute;
    top: 15%;
    transform: rotate(45deg);
    width: 1px
}

.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_wknd button:before,
.rc_body-days-of-month .rc_body-cell.rc_wknd button:before {
    background-color: var(--rc-color-bg-disabled-weekend-cross)
}
// .react-calendar {
//     width: auto;
//     max-width: 100%;
//     background: transparent;
//     border: none;
//     font-size: 14px;
//     font-family: Arial, Helvetica, sans-serif;
//     line-height: 140%;
//     color: $color_primary;
// }
// .react-calendar--doubleView {
//     width: 700px;
// }

// .react-calendar--doubleView .react-calendar__viewContainer {
//     display: flex;
//     margin: -0.5em;
// }

// .react-calendar--doubleView .react-calendar__viewContainer>* {
//     width: 50%;
//     margin: 0.5em;
// }

// .react-calendar,
// .react-calendar *,
// .react-calendar *:before,
// .react-calendar *:after {
//     -moz-box-sizing: border-box;
//     -webkit-box-sizing: border-box;
//     box-sizing: border-box;
// }

// .react-calendar button {
//     margin: 0;
//     border: 0;
//     border-radius: 10px;
//     display: flex;
//     line-height: 140%;
//     align-items: center;
//     outline: none;
//     font-weight: 600;
// }

// .react-calendar button:enabled:hover {
//     cursor: pointer;
// }

// .react-calendar__navigation {
//     display: flex;
//     height: 44px;
//     margin-bottom: 1em;
// }

// .react-calendar__navigation button {
//     min-width: 44px;
//     background: none;
//     color: $color_primary;
// }

// .react-calendar__navigation button:disabled {
//     color: $color_primary;
//     background-color: $color_accent;
// }

// .react-calendar__navigation button:enabled:hover,
// .react-calendar__navigation button:enabled:focus {
//     color: $color_primary;
//     background-color: $color_accent;
// }

// .react-calendar__month-view__weekdays {
//     text-align: center;
//     text-transform: uppercase;
//     font-weight: bold;
//     font-size: 0.75em;
// }

// .react-calendar__month-view__weekdays__weekday {
//     padding: 0.5em;
// }

// .react-calendar__month-view__weekNumbers .react-calendar__tile {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 0.75em;
//     font-weight: bold;
// }

// .react-calendar__month-view__days__day--weekend {
//     color: $color_accent;
// }

// .react-calendar__month-view__days__day--neighboringMonth {
//     color: #757575;
// }

// .react-calendar__year-view .react-calendar__tile,
// .react-calendar__decade-view .react-calendar__tile,
// .react-calendar__century-view .react-calendar__tile {
//     padding: 2em 0.5em;
// }

// .react-calendar__tile {
//     max-width: 100%;
//     padding: 8px;
//     background: none;
//     text-align: center;
//     line-height: 140%;
// }

// .react-calendar__tile:disabled {
//     background-color: red;
// }

// .react-calendar__tile:enabled:hover,
// .react-calendar__tile:enabled:focus {
//     color: $color_primary;
//     background-color: $bg_accent_secondary;
// }

// .react-calendar__tile--now {
//     color: $color_primary;
//     background: $color_accent; // --
// }

// .react-calendar__tile--now:enabled:hover,
// .react-calendar__tile--now:enabled:focus {
//     background: rgba($color: $color_accent, $alpha: .4); // --
// }

// .react-calendar__tile--hasActive {
//     background: #76baff;
// }

// .react-calendar__tile--hasActive:enabled:hover,
// .react-calendar__tile--hasActive:enabled:focus {
//     background: #a9d4ff;
// }

// .react-calendar__tile--active {
//     background: $bg_accent_secondary;
//     color: $color_primary;
// }

// .react-calendar__tile--active:enabled:hover,
// .react-calendar__tile--active:enabled:focus {
//     background: rgba($color: $bg_accent_secondary, $alpha: .4);
// }

// .react-calendar--selectRange .react-calendar__tile--hover {
//     background-color: #e6e6e6;
// }