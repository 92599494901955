@import './../_variables';

.pagination {
    gap: 4px;
    display: flex;
    align-items: center;
    position: relative;
}

.pagination_list {
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 20px;
    left: 0;
    transition-property: height;
    transition-duration: 200ms;
    display: flex;
    background: $bg_gradient_primary;
    border-radius: $border_radius_primary;
    box-shadow: inset 0 0 2px 1px rgba($color: $shadow_primary, $alpha: .45);

    &__content {
        width: 100%;
        margin: 4px;
        padding: 4px 6px 4px 4px;

        gap: 4px;
        display: flex;
        overflow-y: scroll;
        flex-direction: column;
    }

    @include Scroll(pagination_list__content)
}