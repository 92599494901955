// ~~~~ П Л А Н Ш Е Т Ы ~~~~ //

// Экраны по ширине <= 950px
@media (max-width: 950px) {
    .hiddenFor950, #hiddenFor950 {display: none}
    
    .applications_grid_columns {grid-template-columns: 180px 1fr 110px 100px auto}
    .applicationsPromo_grid_columns {grid-template-columns: 180px 1fr 100px auto}
    .listeners_grid_columns {grid-template-columns: 300px 1fr}
    .contracts_grid_columns {grid-template-columns: 60px 140px 1fr 166px}
    .finalExamination_grid_columns {grid-template-columns: 350px 1fr auto}
}

// Экраны по ширине <= 750px
@media (max-width: 750px) {
    #mobileMenu {display: block}
    #mainMenu, .hiddenFor750, #hiddenFor750 {display: none}

    .applications_grid_columns {grid-template-columns: 180px 1fr auto}
    .applicationsPromo_grid_columns {grid-template-columns: 180px 1fr auto}
    .listeners_files_grid_columns {grid-template-columns: 1fr auto}
    .listeners_grid_columns {grid-template-columns: 1fr}

    .contracts_grid_columns {grid-template-columns: 60px 140px 1fr}
    .contracts_grid_columns_listeners {grid-template-columns: 300px 1fr}
    .contract_card_grid_columns_payments {grid-template-columns: 1fr 120px 120px auto}
    .finalExamination_grid_columns {grid-template-columns: 350px 1fr}
    .deliveryCardListeners_grid_columns {grid-template-columns: 1fr 1fr auto}
}

@media (max-height: 950px) {
    .maxHeight {height: 600px;}
}

// ~~~~ С М А Р Т Ф О Н Ы ~~~~ //

// Экраны по ширине <= 550px
@media (max-width: 550px) {
    #visibleForMobile {display: block}
    #hiddenFor550, .hiddenFor550, .hiddenForMobile, #hiddenForMobile {display: none}

    .mobile550 {
        flex-grow: 1;
    }

    .maxHeight {height: 100%}
    .modalMobile_content {height: 100%}

    .page_container-header {flex-wrap: wrap}
    .page_header_for_mobile {width: 100%;}

    .applications_grid_columns {grid-template-columns: 1fr auto}
    .applicationsPromo_grid_columns {grid-template-columns: 1fr auto}

    .contracts_grid_columns {grid-template-columns: 50px 100px 1fr}
    .contracts_grid_columns_listeners {grid-template-columns: 1fr}
    .contract_card_grid_columns_payments {grid-template-columns: 1fr 120px auto}
    .finalExamination_grid_columns {grid-template-columns: 1fr}
    .deliveryCardFiles_grid_columns {grid-template-columns: 1fr auto}
    .deliveryCardListeners_grid_columns {grid-template-columns: 1fr auto}
}
// Экраны по высоте <= 700px
@media (max-height: 700px) {
    #mobileMenu {display: block}
    #mainMenu {display: none}
}
// Экраны по высоте <= 500px
@media (max-height: 500px) {
    .hiddenForMobile, #hiddenForMobile {display: none}

    .maxHeight {height: 100%}
    .modalMobile_content {height: 100%}

    .applications_grid_columns {grid-template-columns: 1fr auto}
    .contract_card_grid_columns_payments {grid-template-columns: 1fr 120px 120px auto}
}