@import './../_variables.scss';

.wrapper {
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color: $shadow_primary, $alpha: .25);
    backdrop-filter: blur(5px);
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    visibility: hidden;
    transition: all .4s ease;

    &.open {
        opacity: 1;
        visibility: visible;

        .content { transform: translateY(0); }
    }

    &.close {
        opacity: 0;
        visibility: hidden;
    }

    .content {
        width: auto;
        height: auto;
        font-size: 14px;
        font-weight: 500;
        font-family: inherit;
        letter-spacing: 2px;
    

        transform: translateY(-600px);
        transition: all .4s ease;

        gap: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}