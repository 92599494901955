.table_badge {
    width: 22px; 
    height: 22px;
    border-radius: 1000px;

    font-size: 10px;
    color: $color_secondary;

    display: flex;
    align-items: center;
    justify-content: center;
}

.table_grid_column {grid-template-columns: 1fr;}

.table_column_data_center {text-align: center}

.applications_grid_columns {grid-template-columns: 180px 1fr 110px 100px 100px auto}
.applicationsPromo_grid_columns {grid-template-columns: 180px 1fr 100px 100px auto}

.contracts_grid_columns {grid-template-columns: 60px 140px 1fr 166px 100px}
.contracts_grid_columns_footer {grid-template-columns: 1fr auto auto}
.contracts_grid_columns_listeners {grid-template-columns: 300px 1fr auto}
.contract_card_grid_columns_payments {grid-template-columns: 180px 1fr 120px 120px auto}
.contract_card_grid_columns_programs {grid-template-columns: 1fr auto}
.contract_card_grid_columns_listeners {grid-template-columns: 1fr auto}

.listeners_grid_columns {grid-template-columns: 350px 1fr 100px}
.listeners_files_header_grid_columns {grid-template-columns: 68px 1fr 100px auto}
.listeners_files_grid_columns {grid-template-columns: 1fr 100px auto}

.companies_grid_columns {grid-template-columns: 350px 1fr 100px}

.finalExamination_grid_columns {grid-template-columns: 350px 1fr 100px 110px auto}

.deliveryCardFiles_grid_columns {grid-template-columns: 1fr 100px auto}
.deliveryCardListeners_grid_columns {grid-template-columns: 1fr 1fr 100px auto}