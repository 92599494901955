.divider_container {
    gap: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider_line_light {
    -webkit-box-shadow: 0px 0px 1px .1px #fff;
    -moz-box-shadow: 0px 0px 1px .1px #fff;
    box-shadow: 0px 0px 1px .1px #fff;
}

.divider_line_dark {
    -webkit-box-shadow: 0px 0px 1px .1px #000;
    -moz-box-shadow: 0px 0px 1px .1px #000;
    box-shadow: 0px 0px 1px .1px #000;
}

.divider_horizontal {
    width: 100%;
}

.divider_vertical {
    height: 100%;
}