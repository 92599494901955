@import './../_variables';

.tab {
    $boxSize: 15px;
    $boxColor: $bg_secondary;

    height: min-content;
    position: relative;
    padding: 12px 10px;
    // background: $boxColor;
    border-radius: 0 0 100% 100%;
    transition: .3s linear;
    cursor: pointer;

    &::after,
    &::before {
        content: ' ';
        // width: $boxSize;
        // height: $boxSize;
        // background: $boxColor;

        top: 0;
        position: absolute;
    }

    &::after {
        // right: -$boxSize;
        border-top-left-radius: 100%;
    }

    &::before {
        z-index: 1;
        // left: -$boxSize;
        border-top-right-radius: 100%;
    }

    &_content {
        // color: $color_accent;

        &::after,
        &::before {
            content: ' ';
            // width: $boxSize;
            // height: $boxSize;
            top: 0;
            position: absolute;
            // background: $boxColor;
            transition: .3s linear;
        }
    
        // &::after {right: -$boxSize}
        // &::before {left: -$boxSize}
    }
}

// .tab_active {
//     padding: 4px 10px 8px 10px;
//     background: #f0f0f0;

//     // .tab_content {
//     //     color: $color_primary;

//     //     &::after,
//     //     &::before {
//     //         background: #f0f0f0;
//     //     }    
//     // }
// }