.container {
    padding: 2px;
    position: absolute;

    min-width: 12px;
    min-height: 12px;

    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    border-radius: 1000px;
    box-shadow: 0 0 2px 1px rgba($color: #FFFFFF, $alpha: 1);
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.topLeft {
    top: 0;
    left: 0;
}
.topRight {
    top: 0;
    right: 0;
}
.bottomLeft {
    bottom: 0;
    left: 0;
}
.bottomRight {
    bottom: 0;
    right: 0;
}