@import './../_variables.scss';

.notificationWrapper {
    width: 80%;
    z-index: 100000;
    max-width: $notification_width;

    top: 20px;
    left: 20px;
    position: fixed;

    gap: 10px;
    display: flex;
    flex-direction: column;
}

.notificationSuccess {
    width: 100%;
    max-width: $notification_width - 34px;

    position: relative;
    padding: 2px 2px 2px 25px;
    border-radius: $border_radius_primary;
    box-shadow: 0 0 2px 1px rgba($color: $shadow_primary, $alpha: .6), 0 0 2px 1px rgba($color: $shadow_primary, $alpha: .6) inset;

    animation: SlideLeft .4s;
    animation-fill-mode: forwards;

    &.exit {
        animation: SlideRight .4s;
        animation-fill-mode: forwards;
    }
}

.notificationContent {
    position: relative;
    font-size: $font_size;
    font-family: inherit;
    font-weight: 300;
    letter-spacing: 1px;
    color: $color_secondary;
    position: relative;
    background: $bg_secondary;
    border-radius: $border_radius_primary - 2px;
    line-height: 140%;
    box-shadow: -4px 0 8px 0px rgba($color: $shadow_secondary, $alpha: .8);

    display: grid;
	align-items: center;
    grid-template-rows: 1fr auto;

    img {
        width: 40px;
        height: 40px;
        left: -21px;
        position: absolute;
    }

    button {
        top: -6px;
        right: -6px;
        position: absolute;
    }

    .message {padding: 15px 15px 15px 30px}
}

.barContainer {
    width: 97%;
    margin: 0 auto;
}
.bar {
    height: 2px;
    background: $bg_accent;
    border-radius: $border_radius_primary;
}

@keyframes SlideLeft {
    0% {margin-left: -130%}
    100% {margin-left: 0}
}

@keyframes SlideRight {
    0% {margin-left: 0}
    100% {margin-left: -130%}
}