.wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;


    .container {
        width: auto;
        height: auto;

        gap: 20px;
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 600;
            text-align: center;
            letter-spacing: 2px;
        }
    }
}