@import './../_variables.scss';

.buttonWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}
button {
    position: relative;
    cursor: pointer;
    user-select: none;
    position: relative;
    letter-spacing: 1px;
    text-decoration: none;
    border-radius: $border_radius_primary;
    font-weight: $font_weight;
    font-size: $font_size;
    font-family: inherit;
    line-height: 140%;
    transition: .4s linear;

    gap: 15px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    &:after {
        content: "";
        display: block;
        position: absolute;
        border-radius: $border_radius_primary;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all .6s;
        box-shadow: 0 0 10px 20px $shadow_accent;
    }

    &:active:after {
        box-shadow: 0 0 0 0 $shadow_accent;
        position: absolute;
        border-radius: $border_radius_primary;
        left: 0;
        top:0;
        opacity: 1;
        transition: 0s;
    }

    &:disabled {
        cursor: not-allowed;
        position: relative;
        color: #D9D9D9;
        background: #f5f5f5;
        border: 0px solid #D9D9D9;
        border-radius: $border_radius_primary - -2px;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            box-shadow: none;
        }
    }
}


.primary {
    color: $color_secondary;
    background: $bg_gradient_accent;

    &:hover {box-shadow: 0 0 2px 1px rgba($color: $shadow_secondary, $alpha: .6), 0 0 10px 5px rgba($color: $shadow_secondary, $alpha: .6) inset}
}

.primaryLight {
    color: $color_primary;
    background: $bg_gradient_primary;

    &:hover {box-shadow: 0 0 2px 1px rgba($color: $shadow_primary, $alpha: .6), 0 0 10px 5px rgba($color: $shadow_primary, $alpha: .6) inset}
}

.primaryDark {
    color: $color_secondary;
    background: $bg_gradient_secondary;

    &:hover {box-shadow: 0 0 2px 1px rgba($color: $shadow_secondary, $alpha: .6), 0 0 10px 5px rgba($color: $shadow_secondary, $alpha: .6) inset}
}


.icon {
    color: $color_accent;
    background: transparent;

    &:hover {box-shadow: 0 0 4px 2px rgba($color: $shadow_accent, $alpha: .8), 0 0 8px 4px rgba($color: $shadow_accent, $alpha: .6) inset}
}
.iconBg {
    color: rgba($color: $color_secondary, $alpha: .8);
    background: $bg_accent;

    &:hover {box-shadow: 0 0 4px 2px rgba($color: $shadow_accent, $alpha: .8), 0 0 8px 4px rgba($color: $shadow_accent, $alpha: .6) inset}
}
.iconBr {
    color: $color_accent;
    border: 1px solid $color_accent;
    background-color: transparent;

    &:hover {box-shadow: 0 0 4px 2px rgba($color: $shadow_accent, $alpha: .8), 0 0 8px 4px rgba($color: $shadow_accent, $alpha: .6) inset}
}

.isLoading {
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(5px);
    background: rgba($color: $bg_primary, $alpha: .65);
    border-radius: $border_radius_primary;
    display: flex;
    align-items: center;
    justify-content: center;
}


.primary, .primaryLight, .primaryDark, .icon, .iconBg, .iconBr {
    &.lg { 
        min-width: 50px;
        min-height: 50px;
    }
    &.md { 
        min-width: 40px;
        min-height: 40px;
    }
    &.sm { 
        min-width: 30px;
        min-height: 30px;
    }
    &.xs { 
        min-width: 20px;
        min-height: 20px;
    }
}