@import './_variables';


$mainMagrin: 2px;
#mobileMenu {display: none}


// Контейнер страницы авторизации авторизации
.auth_container {
    width: 100%;
    max-width: 350px;
    img {max-width: 70px}
}
// Контейнер главной страницы
.main_container {
    height: 100%;
    overflow: hidden;

    &-nav {
        padding: 10px;
        color: $color_accent;
        background-color: $bg_secondary;
        border-radius: $border_radius_primary;
        margin: $mainMagrin 0 $mainMagrin $mainMagrin;

        img { 
            max-width: 40px;
            max-height: 40px;
        }
    }
    &-nav_main {
        flex-grow: 1;
    }
    &-body {
        flex-grow: 1;
        overflow: hidden;
        margin: $mainMagrin $mainMagrin $mainMagrin $mainMagrin;
    }

    .nav_button {
        width: 40px;
        height: 40px;
        position: relative;
        color: $color_accent;
    
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .4s ease-out;
        border-radius: $border_radius_primary;
    
        &:hover {box-shadow: 0px 0px 4px 2px rgba($color: $shadow_secondary, $alpha: .6), 0px 0px 10px 5px rgba($color: $shadow_secondary, $alpha: .6) inset}
    
        &-active, &-active:hover {
            box-shadow: none;
            color: $color_primary;
            background: $bg_gradient_primary;
        }
    }
}
// Контейнер всех страниц
.page_container {
    height: 100%;
    overflow: hidden;
    // position: relative;

    &-header {
        padding: 2px 4px;

        &-title {
            h4 {color: $color_primary}
        }
    }
    &-footer {
        padding: 4px 4px 4px 8px;
        background: $bg_gradient_primary;
        border-radius: $border_radius_primary;
        box-shadow: inset 0 0 2px 1px rgba($color: $shadow_primary, $alpha: .25);

        &-stat span {
            color: $color_accent;
        }
    }
}


// Контейнер модального окна
.modal_container {
    width: 100%;
    padding: 2px;
    overflow: hidden;
    background: $bg_secondary;
    border-radius: $border_radius_primary;
    box-shadow: 0 0 4px 2px rgba($color: $shadow_primary, $alpha: .8);

    &-header {
        padding: 6px 6px;
        h4 {color: $color_secondary}
        h5 {
            opacity: .8;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 2px; 
            color: $color_secondary;
        }
    }
    &-body {
        padding: 6px;
        overflow: hidden;
        position: relative;
        color: $color_primary;

        background: $bg_gradient_primary;
        border-radius: $border_radius_primary - 2px;
        box-shadow: 0px 0px 4px .2px rgba($color: $shadow_primary, $alpha: 1) inset;
    }
    &-content {padding: 4px}
}
@include Scroll(modal_container-content);

// Контейнер видвижного окна
.drawer_container {
    padding: 2px;
    position: relative;
    overflow: hidden;
    background: $bg_secondary;
    border-radius: $border_radius_primary;
    box-shadow: 0 0 4px 2px rgba($color: $shadow_primary, $alpha: .8);

    &-header {
        padding: 2px 6px 4px 6px;
        h4 {color: $color_secondary}
        h5 {
            opacity: .8;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 2px; 
            color: $color_secondary;
        }
    }
    &-body {box-shadow: 0px 0px 4px .2px rgba($color: $shadow_primary, $alpha: 1) inset}
    &-body_bottom_panel {box-shadow: 0px 4px 4px 0px rgba($color: $shadow_primary, $alpha: .4) inset}
    &-body, &-body_bottom_panel {
        height: 100%;
        padding: 4px;
        overflow: hidden;
        position: relative;
        color: $color_primary;

        background: $bg_gradient_primary;
        border-radius: $border_radius_primary - 2px;
    }

    &-content {
        flex-grow: 1;
        padding: 4px 8px 4px 4px;
        overflow-y: scroll
    }
    
    @include Scroll(drawer_container-content);
}


// Кнопка в мобильном меню
.menu_button {
    height: 40px;
    min-height: 40px;
    font-size: 14px;
    position: relative;
    color: $color_accent;

    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease-out;
    border-radius: $border_radius_primary;

    &:hover {box-shadow: 0px 0px 4px 2px rgba($color: $shadow_accent, $alpha: .6), 0px 0px 10px 5px rgba($color: $shadow_accent, $alpha: .6) inset}

    &-active, &-active:hover {
        box-shadow: none;
        color: $color_secondary;
        background: $bg_gradient_accent;
    }
}    
// Кнопка меню для открытия мобильного меню
.button_menu_for_mobile {
    left: 4px;
    top: 4px;
    position: fixed;
    color: $color_accent;
}


// Стиль заголовка для Input
.input_header {
    opacity: .8;
    padding-left: 6px;
    padding-right: 6px;

    p {color: green}
}
.input_error {
    position: relative;
    border-radius: 10px;
    color: $color_primary;
    background-color: transparent;
    transition: background-color .8s ease, color .4s ease;
    box-shadow: 0px 0px 4px 1px rgba($color: $shadow_secondary, $alpha: 0) inset;

    &-active {
        background-color: red;
        color: $color_secondary;
        transition: background-color .8s ease, color .4s ease;
        box-shadow: 0px 0px 4px 1px rgba($color: $shadow_secondary, $alpha: 1) inset;
    }
}

// Link container style
.link_style {
    color: rgba($color: $color_accent, $alpha: .7);

    &:hover {
        color: $color_accent;
    }
}

// Стиль при перетаскивании файлов
.dropZone_container {
    flex-grow: 1;
    display: flex;
    transition: .5s linear;
}
.dropZone_dragAccept {
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
    
    border-radius: $border_radius_primary;
    transition: .5s linear;

    box-shadow: 0 0 40px 20px rgba($color: $bg_accent, $alpha: .45) inset, 0 0 6px 3px rgba($color: $bg_accent, $alpha: .45) inset;
}

.card_style {
    padding: 4px;
    background: $bg_primary;
    border: 1px solid $color_accent;
    border-radius: $border_radius_primary;
}