@import './_variables';
@import './_calendarStyles';
@import './_tableStyles.scss';
@import './main.scss';
@import './mobile.scss';
@import './fonts/acrom/stylesheet.css';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
    margin: 0;
    padding: 0;
    border: none;
    text-decoration: none;
    outline: none !important;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;
}
html, body, #root {
    width: 100%;
    height: 100%;
    font-size: 14px;
    overflow: hidden;
    line-height: 130%;
    font-weight: 500;
    font-style: normal;
    letter-spacing: .5px;
    font-family: 'Inter', sans-serif;
    font-optical-sizing: auto;
    color: $color_primary;
    background: $bg_primary;
    zoom: 1;
}
h1, h2, h3, h4, h5 {
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 1px;
    color: $color_accent;
    font-family: 'Acrom', sans-serif;
}
h1 { font-size: clamp(30px, 3vw, 36px); }
h2 { font-size: clamp(24px, 3vw, 30px); }
h3 { font-size: clamp(18px, 2vw, 24px); }
h4 { font-size: 16px; }
h5 { font-size: 14px; }