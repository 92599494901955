@import './../_variables';


.table_container {
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    gap: 4px;
    display: flex;
    flex-direction: column;

    &__headers {
        gap: 4px;
        display: grid;
        align-items: center;
        color: rgba($color: $color_primary, $alpha: .6);
    }
    &__body {
        flex-grow: 1;
    
        gap: 4px;
        display: flex;
        flex-direction: column;
    }
    &__row {
        position: relative;
        color: $color_secondary;
        transition: .3s ease-in;
        background: $bg_secondary;
        border-radius: $border_radius_primary;
        box-shadow: 0 0 2px 1px rgba($color: #000, $alpha: .20) inset;
    
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr;
    
        &_inset {
            gap: 4px;
            display: grid;
            justify-content: space-between;
            overflow: hidden;

            padding-left: 8px;
            align-items: center;
            color: $color_primary;
            transition: .3s ease-in;
            background: $bg_gradient_primary;
            border-radius: $border_radius_primary - 2px;
            box-shadow: 0 0 2px 1px rgba($color: #000, $alpha: .20) inset;

            &_badge {
                width: 28px;
                height: 28px;
                margin: auto 0;
                position: absolute;
                border-radius: 1000px;
                background: $bg_gradient_primary;
                box-shadow: 2px 0px 2px 0px rgba($color: #000, $alpha: .20) inset;
                
                display: flex;
                align-items: center;
                justify-content: center;
            }
        
            nav {
                width: auto;
                padding: 8px;
        
                gap: 8px;
                display: flex;
                justify-content: flex-end;
                border-radius: $border_radius_primary - 2px;
                box-shadow: 0 0 0px 0px rgba($color: $shadow_primary, $alpha: .4), 0 0 2px .1px rgba($color: #000, $alpha: .20) inset;
            }
        }
        &:hover {
            cursor: pointer;
            transition: .3s ease-in;
            box-shadow: 0px 0px 14px 4px rgba($color: $shadow_secondary, $alpha: .6) inset;
    
            .table_container__row_inset {
                box-shadow: 0 0 14px 4px rgba($color: $shadow_primary, $alpha: .3) inset;
            }
        }
    }
    &__rowCard {
        padding: 3px;
        position: relative;
        color: $color_secondary;
        transition: .3s ease-in;
        background: $bg_secondary;
        border-radius: $border_radius_primary;
        box-shadow: 0 0 2px 1px rgba($color: #000, $alpha: .20) inset;

        gap: 4px;
        display: flex;
        flex-direction: column;

        &_header, &_footer {
            gap: 4px;
            display: grid;
            align-items: center;
        }

        &_header {}

        &_content {
            gap: 2px;
            display: flex;
            flex-direction: column;
        }

        &_footer {}

        &:hover {
            cursor: pointer;
            transition: .3s ease-in;
            box-shadow: 0px 0px 14px 4px rgba($color: $shadow_secondary, $alpha: .6) inset;
        }
    }
}

@include Scroll(table_container__body)