@import './../_variables.scss';

.primary {
    color: $color_primary;
    font-weight: $font_weight;
    font-size: $font_size;

    position: relative;
    border-radius: $border_radius_primary;
    transition: all .4s linear;
    border: 1.5px solid $color_accent;
    background-color: $bg_primary;

    gap: 6px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    input {
        width: 100%;
        height: 100%;
        font-family: inherit;
        background: transparent;
        transition: all .4s;
        position: relative;
        letter-spacing: 1px;
        // border: 1px solid red;

        color: $color_primary;
        font-weight: $font_weight;
        font-size: $font_size;

        &:focus {outline: none !important}
        &:-webkit-autofill {-webkit-background-clip: text}
        
        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color: rgba($color: $color_primary, $alpha: .6);
        }
        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: rgba($color: $color_primary, $alpha: .6);
        }
        &::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: rgba($color: $color_primary, $alpha: .6);
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: rgba($color: $color_primary, $alpha: .6);
        }
        &::-ms-input-placeholder { /* Microsoft Edge */
            color: rgba($color: $color_primary, $alpha: .6);
        }
        &::placeholder { /* Most modern browsers support this now. */
            color: rgba($color: $color_primary, $alpha: .6);
        }

        &:disabled {
            border: 0;
            opacity: .3;
            box-shadow: none;
            cursor: not-allowed;
            pointer-events: none;
        }

        &.lg {height: 46px}
        &.md {height: 36px}
        &.sm {height: 26px}
        &.xs {height: 16px}
    }

    &:focus-within, &:hover {
        border: 1.5px solid rgba($color: $color_accent, $alpha: .6);
        box-shadow: 0 0 2px 1px rgba($color: $shadow_accent, $alpha: .4), 0 0 8px 4px rgba($color: $shadow_accent, $alpha: .8) inset;
    }

    &.lg {padding: 0px 8px}
    &.md {padding: 0px 8px}
    &.sm {padding: 0px 8px}
    &.xs {padding: 0px 8px}
}

.disabled {
    opacity: .3;
    box-shadow: none;
    cursor: not-allowed;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        background: #000;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: .2;
        border-radius: $border_radius_primary - 2px;
    }
}

.isLoading {
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    backdrop-filter: blur(5px);
    background: rgba($color: $bg_secondary, $alpha: .1);
    border-radius: $border_radius_primary;
    display: flex;
    align-items: center;
    justify-content: center;
}