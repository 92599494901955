@import './../variables';


.collapse_classic {
    background: $bg_gradient_primary;
    border-radius: $border_radius_primary;
    box-shadow: inset 0 0 2px 1px rgba($color: $shadow_primary, $alpha: .25);
}

.collapse_header {
    cursor: pointer;
    padding: 6px 8px;
    color: $color_primary;
    box-sizing: border-box;
    
    gap: 6px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
}

.panel {
    transition-property: height;
    transition-duration: 200ms;
}

@include Scroll(panel)